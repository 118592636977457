export default {
  methods: {
    getSelectedMonths(months, selected_ids, selected) {
      const new_months = []
      for (const id of selected_ids || []) {
        if (id && !(selected || []).find(item => item.id === id)) new_months.push(months.find(item => item.id === id))
      }
      return new_months.length ? new_months.concat(selected || []).sort((a, b) => (a.id > b.id ? 1 : -1)) : selected

      /*
      if ((selected_ids || []).length) month_ids = month_ids.concat(selected_ids)
      if ((selected || []).length) {
        selected.forEach(item => month_ids.push(item.id))
      }
      return months.filter(item => month_ids.indexOf(item.id) >= 0) // TODO: use find ?*/
    }
    /*getSelectedMonthIds() {
      const months = []

    }*/
  }
}
